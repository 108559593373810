// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  cataloguePage: (distributor_id: string, slug: string, id: string) =>
    `/catalogue/${distributor_id}/${slug}/${id}`,
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: '',
  changelog: '',
  zoneUI: '',
  minimalUI: '',
  freeUI: '',
  figma: '',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/login`,
      register: `/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    account: `${ROOTS.DASHBOARD}/account`,
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    retailers: {
      root: `${ROOTS.DASHBOARD}/retailers`,
      new: `${ROOTS.DASHBOARD}/retailers/new`,
      list: `${ROOTS.DASHBOARD}/retailers/list`,
      onboarding: `${ROOTS.DASHBOARD}/retailers/onboarding`,
      leads: `${ROOTS.DASHBOARD}/retailers/leads`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/retailers/${id}/edit`,
    },
    cpv: {
      root: `${ROOTS.DASHBOARD}/cpv`,
      list: `${ROOTS.DASHBOARD}/cpv`,
      view: (id: string) => `${ROOTS.DASHBOARD}/cpv/${id}`,
      new: (id: string) => `${ROOTS.DASHBOARD}/cpv/${id}/new`,
    },
    delivery_person: {
      root: `${ROOTS.DASHBOARD}/delivery-person`,
      new: `${ROOTS.DASHBOARD}/delivery-person/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-person/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/delivery-person/list`,
    },
    enquiry: {
      root: `${ROOTS.DASHBOARD}/enquiry`,
      list: `${ROOTS.DASHBOARD}/enquiry/list`,
      retailers: `${ROOTS.DASHBOARD}/enquiry/list/retailers`,
      new_retailers: `${ROOTS.DASHBOARD}/enquiry/list/new-retailers`,
      resolved: `${ROOTS.DASHBOARD}/enquiry/list/resolved`,
    },
    distributors: {
      root: `${ROOTS.DASHBOARD}/distributors`,
      new: `${ROOTS.DASHBOARD}/distributors/new`,
      list: `${ROOTS.DASHBOARD}/distributors`,
      onboarding: `${ROOTS.DASHBOARD}/distributors/onboarding`,
      leads: `${ROOTS.DASHBOARD}/distributors/leads`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/distributors/${id}/edit`,
      retailers: (id: string) => `${ROOTS.DASHBOARD}/distributors/${id}/retailers`,
      catalogue: (id: string) => `${ROOTS.DASHBOARD}/distributors/${id}/catalogue`,
      orders: (id: string) => `${ROOTS.DASHBOARD}/distributors/${id}/orders`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      updateHistory: `${ROOTS.DASHBOARD}/product/update-history`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      sendCampaigns: `${ROOTS.DASHBOARD}/product/sendCampaigns`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    catalogue: {
      root: `${ROOTS.DASHBOARD}/catalogue`,
      updateHistory: `${ROOTS.DASHBOARD}/catalogue/update-history`,
      new: `${ROOTS.DASHBOARD}/catalogue/new`,
      sendCampaigns: `${ROOTS.DASHBOARD}/catalogue/send-campaigns`,
      campaigns: `${ROOTS.DASHBOARD}/catalogue/campaigns`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/orders`,
      retail_tech: `${ROOTS.DASHBOARD}/orders/retail-tech`,
      ondc: `${ROOTS.DASHBOARD}/orders/ondc`,
      new: `${ROOTS.DASHBOARD}/orders/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/orders/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    prepaid: {
      root: `${ROOTS.DASHBOARD}/prepaid`,
      statement: `${ROOTS.DASHBOARD}/prepaid/statement`,
      pending_failed: `${ROOTS.DASHBOARD}/prepaid/pending-failed`,
      details: (id: string) => `${ROOTS.DASHBOARD}/prepaid/${id}`,
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
